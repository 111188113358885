<template>
  <div>
    <activation-toggle
      v-if="listing.is_published"
      :activated="listing.is_available"
      :listing-uuid="listing.uuid"
      @actionDone="actionDoneHandler"
      :fromMylisting="false"
    />
    <publish-toggle
      v-else
      :listing-uuid="listing.uuid"
      @actionDone="actionDoneHandler"
      :fromMylisting="false"
    />
    <edit-btn :listing-uuid="listing.uuid" />
    <duplicate-btn class="mt-2" v-if="listing.is_published" :listing-uuid="listing.uuid" />
    <delete-btn
      class="mt-2"
      @actionDone="$router.push('/dashboard/mylisting')"
      :listing-uuid="listing.uuid"
      v-if="!listing.is_published"
      :fromMylisting="false"
    />
  </div>
</template>

<script>
import ActivationToggle from '@/components/mylisting/buttons/activation-toggle';
import EditBtn from '@/components/mylisting/buttons/edit-btn';
import DuplicateBtn from '@/components/mylisting/buttons/duplicate-btn';
import PublishToggle from '@/components/mylisting/buttons/publish-toggle';
import DeleteBtn from '@/components/mylisting/buttons/delete-btn.vue';

export default {
  name: 'owner-section',
  components: { ActivationToggle, EditBtn, DuplicateBtn, PublishToggle, DeleteBtn },
  props: ['listing'],
  methods: {
    actionDoneHandler() {
      this.$router.go(0);
    },
  },
};
</script>

<style scoped></style>
